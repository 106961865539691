import React from "react"

export default function NoteHeader() {
    function createNote () {

    }


    return (
        <div className="header">
            <div className="header-title">
              <p>{'<3'}</p>
              
            </div>
          </div>
    )
} 