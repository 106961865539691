export default [
    {
        id: 1,
        quest: "What is the main purpose of 'Emo Diary'?",
        answer: "Our goal is to create a space where you feel yourself as at home. Don't worry - here everybody is accepted and loved <3 You can share your EMOtions without feeling that someone hates you."
    },
    {
        id: 2,
        quest: "Can I use 'Emo Diary' if I'm not emo?",
        answer: "There are no restrictions, limitations & frames here! (Of course if you're not moskal' )"
    }, 
    {
        id: 3,
        quest: "How can I get the support?",
        answer: "The best way is to contact with psycologist. If this is a problem right now, you can click 'Support'-link on the top of web-page or use 'Emo Diary' to express your feelings <3"
    },
    {
        id: 4,
        quest: "How can I become emo?",
        answer: "Good news: if you are here, you are already emo! The most important is be yourself, listen to emo music and use this app every day! ;)"
    }
]